import { useState } from "react";
import axios from "axios";
import logo from './Logo.svg';
import name from './NameAndTagline.svg';

function App() {

  const [email, setEmail] = useState("");
  const [response, setResponce] = useState("");

  const handleMail = async (e) => {
    e.preventDefault();
    await axios.post("https://officialpage.onrender.com/api/postemail", { email }).then((res,err) => {
      setResponce(res.data.response)
    })
  }

  return (
    <div className="w-screen h-screen text-center flex flex-col justify-center items-center gap-10 font-poppins text-xl 
    sm:text-base sm:p-3 sm:gap-7 sm:h-full">
      <div className="flex flex-col justify-center items-center align-middle">
        <img alt="" src={name} className="h-[82px]" />
      </div>
      <div className="">
        <h1 className="text-blue-700 font-bold text-[32px] mb-4">
          Coming soon
          <span className="text-4xl animate-ping delay-0">.</span>
          <span className="text-4xl animate-ping delay-100">.</span>
          <span className="text-4xl animate-ping delay-200">.</span>
          <span className="text-4xl animate-ping delay-400">.</span>
        </h1>
        <h3>Build stronger professional connections with <span className="italic font-semibold">Sprexcel</span>. Stay tuned for updates!</h3>
      </div>
      <div className="flex flex-col justify-center items-center align-middle">
        <img alt="" src={logo} className="h-[98px]" />
      </div>
      <div>
        <form onSubmit={handleMail} className="flex flex-col items-center">
          <input type="email" placeholder='Enter your mail id for updates!' 
          className="w-[600px] text-center bg-slate-50 p-2 rounded-md mb-5 focus:outline-none sm:w-[300px]"
          onChange={e => setEmail(e.target.value)} />
          <button type="submit" class="w-fit relative inline-flex items-center justify-center p-2 px-10 py-0 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 bg-blue-600 rounded-md shadow-md group">
            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-blue-600 group-focus:translate-x-0 ease">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span class="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-focus:translate-x-full ease">Be Notified!</span>
            <span class="relative invisible">Submit</span>
          </button>
        </form>
        {response === "Email Exit" && <h1 className="text-red-600 text-sm mt-2">Email exit in our database! Try with new mail</h1>}
        {response === "Saved successfully" && <h1 className="text-green-900 text-sm mt-2">Email saved in our database successfully!</h1>}
      </div>
      <div className="flex flex-col justify-center items-center text-l sm:text-[15px]">
        <h3>Thank you for visiting our temporary landing page. </h3>
        <h3>We're excited to be working on a platform that will revolutionize professional networking.</h3>
        <h3>Stay tuned for more updates!</h3>
      </div>
    </div>
  );
}

export default App;
